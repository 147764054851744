<script setup lang="ts">
import LabelWithTooltip from '@level3/components/LabelWithTooltip.vue'
import DataAssetModuleContainer from './DataAssetModuleContainer.vue'

type ModelType = {
  daysDelay: number
  daysDifference: number
}

const model = defineModel<ModelType>({
  default: {
    daysDelay: 0,
    daysDifference: 0,
  },
})
</script>

<template>
  <DataAssetModuleContainer
    title="Asset settings"
    icon-class="i-solar-settings-bold-duotone text-foreground"
  >
    <div class="grid grid-cols-[380px_380px] gap-6">
      <div class="space-y-2">
        <div class="flex items-center gap-2">
          <LabelWithTooltip
            label="Days delay within file"
            tooltip="Number of days to wait before processing the file after its creation date"
          />
        </div>
        <div class="w-full flex items-center">
          <NInputNumber v-model:value="model.daysDelay" :min="0" class="w-full">
            <template #suffix>
              <span class="text-foreground">days</span>
            </template>
          </NInputNumber>
        </div>
      </div>

      <div class="space-y-2">
        <div class="flex items-center gap-2">
          <LabelWithTooltip
            label="Days difference between run date and file date"
            tooltip="Expected difference in days between the pipeline run date and the file date"
          />
        </div>
        <div class="w-full flex items-center">
          <NInputNumber
            v-model:value="model.daysDifference"
            :min="0"
            class="w-full"
          >
            <template #suffix>
              <span class="text-foreground">days</span>
            </template>
          </NInputNumber>
        </div>
      </div>
    </div>
  </DataAssetModuleContainer>
</template>

<style scoped>
:deep(.n-input-number) {
  width: 100%;
}

:deep(.n-input-number-suffix) {
  margin-right: 12px;
}
</style>
