<script lang="ts" setup>
import DataAssetSubHeader from '@level3/features/dataAssets/components/DataAssetSubHeader/DataAssetSubHeader.vue'
import {
  type DataAssetType,
  type DataAssetSource,
} from '@level3/features/dataAssets/types/asset'
import DataAssetNameAndDescription from '@level3/features/dataAssets/components/DataAssetProperties/DataAssetNameAndDescription.vue'
import DataAssetDetails from '@level3/features/dataAssets/components/DataAssetProperties/DataAssetDetails.vue'
import DataAssetSettings from '@level3/features/dataAssets/components/DataAssetProperties/DataAssetSettings.vue'

export type FormData = {
  assetType: DataAssetType[]
  assetName: string
  description: string
  fileFormat: DataAssetSource
  stopPipelineIfMissing: boolean
  daysDelay: number
  daysDifference: number
}
const formData = defineModel<FormData>()

defineProps<{
  isBaseAsset: boolean
}>()

defineEmits<{
  save: []
}>()
</script>

<template>
  <div class="flex flex-col gap-5">
    <DataAssetSubHeader
      :step-number="1"
      title="Set properties for the customer population asset"
      description="Provide details about your data asset."
    >
      <template #actions>
        <NButton type="primary" @click="$emit('save')">
          <template #icon>
            <div class="i-solar-diskette-linear" />
          </template>
          Save
        </NButton>
      </template>
    </DataAssetSubHeader>
    <DataAssetNameAndDescription
      v-model="formData"
      :is-base-asset="isBaseAsset"
    />
    <DataAssetDetails v-model="formData" />
    <DataAssetSettings v-model="formData" />
  </div>
</template>
