/* eslint-disable no-console */
// TODO: This is meant to be temporary, we are working on adding the possibility of having local state on Forms
// we will be moving away from global states (including Singletons) when is not needed ASAP
import AppSettings from '@app/AppSettings'
import { store } from '@app/store'
import { defineStore } from 'pinia'
import posthog from 'posthog-js'

const _FEATURE_FLAGS = [
  'enable-ofe-in-fof',
  'email-variants-guardrails',
  'enable-calendar-sends',
  'enable-level-3-v2',
  'enable-uplift-report-p-value',
  'enable-theme-editor',
  'theme-v2',
  'enable-looker-embed',
] as const
type FeatureFlag = typeof _FEATURE_FLAGS[number]

const LOCAL_FEATURE_FLAGS: FeatureFlag[] = (import.meta.env.VITE_LOCAL_FEATURE_FLAGS)?.split(',') || []

export const usePosthogStore = defineStore('PosthogStore', () => {
  const isPosthogEnabled = toRef(!!import.meta.env.VITE_POSTHOG_API_KEY && !!import.meta.env.VITE_POSTHOG_API_HOST)
  const featureFlags = ref<FeatureFlag[]>([])
  const hasLoadedFeatureFlags = ref(false)
  const userEmail: ComputedRef<string> = computed(() => store.getters['user/email'])
  const userName: ComputedRef<string> = computed(() => store.getters['user/username'])
  const userRole: ComputedRef<string> = computed(() => store.getters['user/role'].type)

  watch(isPosthogEnabled, () => {
    if (isPosthogEnabled.value) {
      posthog.onFeatureFlags((flags) => {
        featureFlags.value = flags as FeatureFlag[] // We dont have access to the types, no other way besides `as` here
        hasLoadedFeatureFlags.value = true
      })
    }
    else {
      featureFlags.value = LOCAL_FEATURE_FLAGS
    }
  }, { immediate: true })

  const { clientName, experimenterName } = AppSettings

  watch([userEmail, userName, userRole], () => {
    if (isPosthogEnabled) {
      posthog.identify(
        userEmail.value,
        { email: userEmail.value, name: userName.value, role: userRole.value },
      )
      posthog.reloadFeatureFlags()
    }
    else {
      console.debug('Posthog -> identify', { userEmail, userName, userRole, isPosthogEnabled })
    }
  }, { immediate: true })

  watch(clientName, () => {
    if (isPosthogEnabled) {
      posthog.setPersonProperties({ client: clientName.value || null })
    }
    else {
      console.debug('Posthog -> set person property', { client: clientName.value || null, isPosthogEnabled })
    }
  }, { immediate: true })

  watch(experimenterName, () => {
    if (isPosthogEnabled) {
      posthog.setPersonProperties({ experimenter: experimenterName.value || null })
    }
    else {
      console.debug('Posthog -> set person property', { experimenter: experimenterName.value || null, isPosthogEnabled })
    }
  }, { immediate: true })

  return {
    hasLoadedFeatureFlags,
    featureFlags,
  }
})
