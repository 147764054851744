<script setup lang="ts">
import {UseClipboard} from '@vueuse/components'
import type {DataAssetType} from '@level3/features/dataAssets/types/asset'
import LabelWithTooltip from '@level3/components/LabelWithTooltip.vue'
import DataAssetModuleContainer from './DataAssetModuleContainer.vue'

withDefaults(defineProps<{
  isBaseAsset: boolean
}>(), {  })

const model = defineModel<{
  assetType: DataAssetType | DataAssetType[]
  assetName: string
  description: string
}>({
  default: {
    assetType: [],
    assetName: '',
    description: '',
  },
})

watch(
  () => __props.isBaseAsset,
  () => {
    if (__props.isBaseAsset) {
      model.value.assetType = ['customer_population']
    }
  },
  {immediate: true},
)

const gbqName = computed(() =>
  model.value.assetName.toLowerCase().replaceAll(/\s+/g, '_'),
)
</script>

<template>
  <DataAssetModuleContainer
    title="Name & description"
    icon-class="i-solar-document-bold text-muted"
  >
    <div class="grid grid-cols-2 gap-8">
      <div class="space-y-4">
        <div class="space-y-2">
          <div class="flex items-center gap-2">
            <LabelWithTooltip label="Asset type" class="font-medium">
              <template #tooltip>
                Tag to mark important assets. Required for the Customer
                population asset.
              </template>
            </LabelWithTooltip>
          </div>
          <NSelect
            v-model:value="model.assetType"
            :options="
              !isBaseAsset
                ? [
                    {label: 'Conversion', value: 'conversion'},
                    {label: 'Engagement', value: 'engagement'},
                    {label: 'Activation', value: 'activation'},
                    {label: 'Reporting', value: 'reporting'},
                  ]
                : []
            "
            :disabled="isBaseAsset"
            multiple
            class="w-full"
            placeholder="Select asset type"
          />
        </div>

        <div class="space-y-2">
          <label class="font-medium">Asset name</label>
          <NInput
            v-model:value="model.assetName"
            class="w-full"
            placeholder="Customer base"
          />
        </div>

        <div class="w-full rounded-lg bg-muted p-4 space-y-2">
          <div class="flex items-center gap-2">
            <span class="text-sm text-muted-foreground">GBQ name:</span>
            <UseClipboard v-slot="{copy, copied}">
              <NTooltip placement="right">
                <template #trigger>
                  <div
                    class="group flex cursor-pointer items-center gap-1 text-sm font-mono hover:text-primary"
                    :class="{'text-primary': copied}"
                    @click="() => copy(gbqName)"
                  >
                    {{ gbqName }}
                    <div
                      v-if="!copied"
                      class="i-solar-copy-linear hidden text-xs group-hover:block"
                    />
                  </div>
                </template>
                Copy GBQ name
              </NTooltip>
            </UseClipboard>
          </div>
          <p class="text-sm text-foreground">
            This is a formatted version of the Asset name, and it can't be
            changed after the first save.
          </p>
        </div>
      </div>

      <div class="space-y-2">
        <label class="font-medium">Description</label>
        <NInput
          v-model:value="model.description"
          type="textarea"
          placeholder="The base customer population."
          :autosize="{
            minRows: 8,
            maxRows: 9,
          }"
        />
      </div>
    </div>
  </DataAssetModuleContainer>
</template>
