<script setup lang="ts">
import AssetsList from '@level3/features/dataAssets/components/AssetsList/AssetsList.vue'
import {NButton} from 'naive-ui'
import {usePath} from '@level3/features/navigation/composables/usePath.ts'

const {clientSlug, pipelineSlug} = usePath()
</script>

<template>
  <div class="p-12">
    <div class="mb-8 flex items-center justify-between">
      <h1 class="text-2xl font-bold">Data Assets</h1>
      <RouterLink
        :to="{
          name: 'create-data-asset-v2',
          params: {
            clientSlug,
            pipelineSlug,
          },
        }"
      >
        <NButton type="primary">
          <template #icon>
            <div class="i-solar-add-circle-linear" />
          </template>
          Create Data Asset
        </NButton>
      </RouterLink>
    </div>
    <AssetsList :client-slug="clientSlug" :pipeline-slug="pipelineSlug" />
  </div>
</template>
