<script lang="ts" setup>
import {type DataAssetType} from '@level3/features/dataAssets/types/asset'
import DataAssetProperties from './DataAssetProperties.vue'

const formData = ref({
  assetType: [] as DataAssetType[],
  assetName: '',
  description: '',
  fileFormat: 'sample_file',
  stopPipelineIfMissing: false,
  daysDelay: 0,
  daysDifference: 0,
})

const isBaseAsset = ref(true) // Will be implemented on Data Asset ConMan integration

const handleUpdate = () => {
  // Implement save logic for managing assets
  // Will be implemented on Data Asset ConMan integration
}
</script>

<template>
  <DataAssetProperties
    :form-data="formData"
    :is-base-asset="isBaseAsset"
    @save="handleUpdate"
  />
</template>
