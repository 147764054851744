<script setup lang="ts">
import {h, type VNode} from 'vue'

type Props = {
  title: string
  iconClass: string
}

withDefaults(defineProps<Props>(), {  })

const handleCreateTitleWithIcon = (): VNode =>
  h('div', {class: 'flex items-center gap-2'}, [
    h('div', {class: __props.iconClass}),
    h('p', {class: 'text-lg font-medium'}, __props.title),
  ])
</script>

<template>
  <NCard :title="handleCreateTitleWithIcon">
    <slot />
  </NCard>
</template>

<style scoped></style>
