/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Why are these colors in HSL?
 *
 * - HSL is easier to reason about for color contrast and logical color schemes than HEX
 * - Unocss has a opacity variable that is easier to use with HSL than HEX
 * example:
 *
 * ```html
 * <div class="bg-background/50">
 * ```
 */
export const primitiveColors = {
  white: '0 0% 100%', // #FFFFFF
  black: '0 0% 0%', // #000000
  neutral: {
    50: '0 0% 98%', // #FAFAFA
    100: '0 0% 96%', // #F5F5F5
    200: '0 0% 90%', // #E5E5E5
    300: '0 0% 83%', // #D4D4D4
    400: '0 0% 64%', // #A3A3A3
    500: '0 0% 45%', // #737373
    600: '0 0% 32%', // #525252
    700: '0 0% 25%', // #404040
    800: '0 0% 15%', // #262626
    900: '0 0% 9%', // #171717
    950: '0 0% 4%', // #0A0A0A
  },
  light: {
    50: '0 0% 99%', // #FCFCFC
    100: '0 0% 99%', // #FCFCFC
    200: '0 0% 98%', // #FAFAFA
    300: '210 17% 98%', // #FAFBFC
    400: '0 0% 96%', // #F5F5F5
    500: '0 0% 95%', // #F2F2F2
    600: '210 17% 95%', // #F0F2F4
    700: '210 16% 93%', // #ECF0F3
    800: '210 14% 89%', // #E1E6EA
    900: '200 10% 88%', // #DFE3E6
    950: '206 10% 86%', // #D9DFE3
  },
  dark: {
    50: '0 0% 29%', // #4A4A4A
    100: '0 0% 24%', // #3D3D3D
    200: '0 0% 20%', // #333333
    300: '0 0% 18%', // #2E2E2E
    400: '0 0% 13%', // #212121
    500: '0 0% 12%', // #1F1F1F
    600: '240 3% 11%', // #1C1C1E
    700: '0 0% 11%', // #1C1C1C
    800: '0 0% 9%', // #171717
    900: '0 0% 6%', // #0F0F0F
    950: '0 0% 3%', // #080808
  },
  purple: {
    50: '267 77% 95%', // #F3EBFF
    100: '264 78% 88%', // #E2D1FF
    200: '264 79% 80%', // #CEB3FF
    300: '265 80% 71%', // #B792FF
    400: '266 79% 63%', // #A374FF
    500: '266 78% 56%', // #9256FF
    600: '264 73% 53%', // #884DFF
    700: '266 62% 46%', // #7A3FEF
    800: '266 62% 39%', // #6735CB
    900: '267 62% 33%', // #572DAB
    950: '273 78% 25%', // #451B94
  },
  orange: {
    50: '7 58% 94%', // #FEF1EC
    100: '15 88% 86%', // #FFD9C2
    200: '15 90% 78%', // #FFC4A1
    300: '15 90% 70%', // #FFAE80
    400: '15 91% 63%', // #FF9B63
    500: '15 92% 58%', // #FF8C4B
    600: '15 83% 55%', // #F27B3D
    700: '15 73% 51%', // #E06B2F
    800: '15 69% 48%', // #D46329
    900: '15 69% 42%', // #BA5724
    950: '15 83% 32%', // #A13E13
  },
  green: {
    50: '153 47% 92%', // #E5F6EF
    100: '150 47% 81%', // #BFEBD8
    200: '152 48% 69%', // #8FDBB8
    300: '153 50% 56%', // #5AC793
    400: '158 73% 42%', // #1DB068
    500: '156 100% 34%', // #00A352
    600: '156 100% 31%', // #00944B
    700: '155 100% 27%', // #008141
    800: '154 100% 24%', // #00723A
    900: '151 100% 18%', // #00542A
    950: '152 85% 13%', // #083D1F
  },
  red: {
    50: '0 85% 97%', // #FEF2F2
    100: '0 93% 94%', // #FFE4E4
    200: '0 96% 89%', // #FFCECE
    300: '0 93% 81%', // #FFB0B0
    400: '0 90% 70%', // #FF8585
    500: '0 84% 60%', // #F25D5D
    600: '0 72% 50%', // #E63333
    700: '0 73% 41%', // #BF2626
    800: '0 70% 35%', // #A31F1F
    900: '0 62% 30%', // #8A1B1B
    950: '0 74% 15%', // #4D0D0D
  },
  yellow: {
    50: '47 100% 96%', // #FFFBE6
    100: '47 96% 88%', // #FFF4BF
    200: '48 96% 76%', // #FFEA80
    300: '45 96% 64%', // #FFD633
    400: '43 96% 56%', // #FFC700
    500: '37 92% 50%', // #F2B705
    600: '32 94% 43%', // #E69C00
    700: '25 90% 37%', // #CC7A00
    800: '22 82% 31%', // #A65D00
    900: '21 77% 26%', // #8C4E00
    950: '20 91% 14%', // #4D2800
  },
  blue: {
    50: '204 100% 97%', // #F0F9FF
    100: '204 93% 93%', // #E0F2FF
    200: '200 94% 86%', // #BAE6FF
    300: '199 95% 73%', // #7CC7FF
    400: '198 93% 59%', // #33A9FF
    500: '198 88% 48%', // #0A91F5
    600: '200 98% 39%', // #0072E6
    700: '201 96% 32%', // #005CBF
    800: '200 89% 27%', // #004D99
    900: '202 80% 23%', // #003D80
    950: '204 80% 15%', // #002952
  },
} as const
/* eslint-enable @typescript-eslint/naming-convention */

export const brandedColors = {
  ash: '#E5E7EB',
  lightpurple: '#B2A1FF',
  purpledefault: primitiveColors.purple[500],
  darkpurple: primitiveColors.purple[950],
  orange: primitiveColors.orange[500],
  green: primitiveColors.green[300],
}
