import {getDataAssets} from '@level3/features/dataAssets/services/dataAssets'
import {usePath} from '@level3/features/navigation/composables/usePath'
import {useQuery} from '@tanstack/vue-query'
import Global from '@shared/utils/global'

export const useDataAssets = () => {
  const {clientSlug, pipelineSlug} = usePath()

  const {
    data: dataAssets,
    isLoading: isLoadingDataAssets,
    error,
  } = useQuery({
    queryKey: [clientSlug, pipelineSlug, 'assets'],
    queryFn: async () => getDataAssets(clientSlug.value, pipelineSlug.value),
    refetchOnMount: true,
  })

  watch(error, (error) => {
    if (error) {
      Global.error(error.message)
    }
  })

  return {
    dataAssets,
    isLoadingDataAssets,
  }
}
