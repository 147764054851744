<script setup lang="ts">
interface Option {
  key: string
  label: string
  content: VNode
}
interface Props {
  options: Option[]
}

defineProps<Props>()

const selected = defineModel<Options['key']>({ required: true })
</script>

<template>
  <div class="grid-toggle-cards grid grid-cols-2 items-stretch gap-4">
    <div v-for="option in options" :key="option.key" class="toggle-card cursor-pointer" :selected="selected === option.key" rounded="md" relative="~" @click="selected = option.key">
      <div v-if="selected === option.key" class="i-solar-check-circle-outline" absolute="~" top="2" left="2" z="10" text="offerfit-bright-purple" />
      <NCard :segmented="{ footer: true }" h="full" rounded="!md" hoverable>
        <div class="option-content p-4">
          <component :is="option.content" class="w-full" />
        </div>
        <template #footer>
          <div class="label text-center">
            {{ option.label }}
          </div>
        </template>
      </NCard>
    </div>
  </div>
</template>

<style scoped>
.toggle-card {
  &[selected="true"] {
    @apply ring-offerfit-bright-purple ring-1;
    .label {
      @apply text-offerfit-bright-purple font-bold;
    }
  }
}
</style>
