<script setup lang="ts">
import { NImage } from 'naive-ui'
import TokensMinimapSfmcLaunch from '@/level4/views/modules/launch-sfmc/TokensMinimapSfmcLaunch.vue'

import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import CopyButton from '@/level4/components/common/CopyButton.vue'
import type { ImportedEmailTemplate } from '@/level4/composables/useExperimenterFormState.ts'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState.ts'
import type { CreatedTemplatesMap } from '@/level4/models/server/FormStateModel.ts'

const props = defineProps<{
  stepName: string
}>()

const { experimenterFormState } = useExperimenterFormState()

const platformParams = computed<string>(() => {
  return experimenterFormState.value?.usecase?.config?.marketing_platform_connection?.platform_params?.sfmc_init_values ?? experimenterFormState.value?.usecase?.config?.marketing_platform_connection?.platform_params
})

async function copyClickHandler(value: string) {
  await navigator.clipboard.writeText(value)
}

const columns = computed(() => {
  return [
    { title: 'BASE EMAIL', key: 'name', render(row) {
      return h('div', { class: 'flex' }, [
        h(NImage, { src: row.thumbnail, width: '60', alt: 'template thumbnail' }),
        h('span', { class: 'flex items-center ml-2' }, [
          h('span', row.name),
          h(CopyButton, {
            onClick: () => copyClickHandler(row.name),
          }),
        ]),
      ])
    } },
    { title: 'EMAIL TEMPLATE ID', key: 'id', render(row) {
      return h('div', { class: 'flex items-center' }, [
        h('span', row.offerfitId),
        h(CopyButton, {
          onClick: () => copyClickHandler(row.offerfitId),
        }),
      ])
    } },
  ]
})

const templatesData = computed(() => {
  return Object.keys(experimenterFormState.value?.offerfitCreatedTemplates)?.map((templateID: string) => {
    const templateItem: CreatedTemplatesMap = experimenterFormState.value?.offerfitCreatedTemplates[templateID]
    return {
      offerfitId: templateItem.id,
      name: templateItem.name,
      thumbnail: experimenterFormState.value?.currentImportedTemplates?.find((item: ImportedEmailTemplate) => item.id === templateID)?.thumbnail_url,
    }
  })
})

defineExpose<FormExposedProperties>({
  nextFormButtonText: 'Validate results',
})
</script>

<template>
  <WizardFormPageLayout
    containerSize="medium"
    supertitle="OfferFit successfully initialized a Journey in your SFMC instance"
    title="Review and activate the Journey in SFMC"
  >
    <TokensMinimapSfmcLaunch :stepName="props.stepName" />
    <div class="mt-6 flex justify-center">
      <BasicLink
        href="https://app.tango.us/app/workflow/-WIP--Reporting-on-BAU-in-the-OfferFit-Self-Serve-portal-18311ba85feb47f3adaf138f38d1b8d8"
        target="_blank"
        class="cursor-pointer font-medium text-link"
        withExternalIcon
      >
        How to review and activate a Journey in SFMC
      </BasicLink>
    </div>
    <NCard :segmented="{ content: true }" class="mt-6">
      <template #header>
        Resources to use
      </template>
      <BlueInfoMessage subtitle="Some of the setup steps will require the information below, which is specific to your Experimenter. The linked instructions references these resources where relevant." />
      <div class="mt-4">
        <p class="text-base font-medium">
          Journey name:
        </p>
        <span>{{ platformParams?.journey_name }}</span>
        <CopyButton class="ml-2" @click="copyClickHandler(platformParams?.journey_name)" />
      </div>
      <div>
        <p class="mb-2 mt-4 text-base font-medium">
          Emails:
        </p>
        <NDataTable :columns="columns" :data="templatesData" />
      </div>
    </NCard>
  </WizardFormPageLayout>
</template>
