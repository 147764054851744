import {type Component} from 'vue'
import DataAssetPreprocess from '../components/DataAssetPreprocess/DataAssetPreprocess.vue'
import DataAssetDataJoining from '../components/DataAssetDataJoining/DataAssetDataJoining.vue'
import DataAssetTransformations from '../components/DataAssetTransformations/DataAssetTransformations.vue'
import DataAssetFeatures from '../components/DataAssetFeatures/DataAssetFeatures.vue'
import CreateDataAssetProperties from '../components/DataAssetProperties/CreateDataAssetProperties.vue'
import ManageDataAssetProperties from '../components/DataAssetProperties/ManageDataAssetProperties.vue'

export type Tab = {
  name: string
  icon: string
  title: string
  component: Component
}

export function useDataAssetTabs(operation: 'create' | 'manage') {
  const tabs: Tab[] = [
    {
      name: 'properties',
      icon: 'i-solar-pen-new-square-outline',
      title: 'Properties',
      component:
        operation === 'create'
          ? (CreateDataAssetProperties as Component)
          : (ManageDataAssetProperties as Component),
    },
    {
      name: 'preprocess',
      icon: 'i-solar-clipboard-check-outline',
      title: 'Preprocess',
      component: DataAssetPreprocess as Component,
    },
    {
      name: 'data-joining',
      icon: 'i-solar-link-minimalistic-outline',
      title: 'Data Joining',
      component: DataAssetDataJoining as Component,
    },
    {
      name: 'transformations',
      icon: 'i-solar-clipboard-add-outline',
      title: 'Transformations',
      component: DataAssetTransformations as Component,
    },
    {
      name: 'features',
      icon: 'i-solar-server-outline',
      title: 'Features',
      component: DataAssetFeatures as Component,
    },
  ]

  return {
    tabs,
  }
}
