<script setup lang="ts">
import {useDataAssetTabs} from '@level3/features/dataAssets/composables/useDataAssetTabs'
import {useDataAssetOptions} from '@level3/features/dataAssets/composables/useDataAssetOptions'
import DataAssetHeader from '@level3/features/dataAssets/components/DataAssetHeader/DataAssetHeader.vue'
import {computed} from 'vue'

const {options, dataAssetSlug, isLoadingDataAssets} = useDataAssetOptions()
const activeTab = useRouteQuery<string>('tab')

const assetType = computed(() => {
  if (!isLoadingDataAssets.value) {
    return options.value.find((option) => option.value === dataAssetSlug.value)
      ?.type
  }

  return undefined
})

const {tabs} = useDataAssetTabs('manage')
</script>

<template>
  <div class="mx-auto flex flex-col gap-6 py-9 container">
    <DataAssetHeader
      v-model:dataAssetSlug="dataAssetSlug"
      :options="options"
      :asset-type="assetType"
      :is-loading="isLoadingDataAssets"
    />
    <NTabs v-model:value="activeTab" type="line" animated>
      <NTabPane
        v-for="tab in tabs"
        :key="tab.name"
        :name="tab.name"
        class="mb-4"
        :tab="
          h('div', {class: 'flex items-center gap-1'}, [
            h('span', {class: tab.icon}),
            h('p', tab.title),
          ])
        "
      >
        <component :is="tab.component" class="tab-content" />
      </NTabPane>
    </NTabs>
  </div>
</template>
