<script setup lang="ts">
import CardRadioGroup from '@level3/components/CardRadioGroup.vue'
import LabelWithTooltip from '@level3/components/LabelWithTooltip.vue'
import type {DataAssetSource} from '@level3/features/dataAssets/types/asset.ts'
import DataAssetModuleContainer from './DataAssetModuleContainer.vue'

type ModelType = {
  fileFormat: DataAssetSource
  sampleData?: File
  stopPipelineIfMissing: boolean
  filePath?: string
}

const model = defineModel<ModelType>({
  default: {
    fileFormat: 'sample_file',
    stopPipelineIfMissing: false,
  },
})

const fileInput = ref<HTMLInputElement>()

const handleFileUpload = (event: Event) => {
  const input = event.target as HTMLInputElement
  const file = input.files?.[0]

  if (file) {
    model.value = {
      ...model.value,
      sampleData: file,
    }
  }

  // Reset input so the same file can be uploaded again if needed
  if (fileInput.value) {
    fileInput.value.value = ''
  }
}

const handleFileRemove = () => {
  model.value = {
    ...model.value,
    sampleData: undefined,
  }
}

const formatFileSize = (bytes: number) => {
  const mb = bytes / (1024 * 1024)
  return `${mb.toFixed(1)} MB`
}

const handleDrop = (event: DragEvent) => {
  event.preventDefault()
  const file = event.dataTransfer?.files[0]
  if (file) {
    model.value = {
      ...model.value,
      sampleData: file,
    }
  }
}

const handleDragOver = (event: DragEvent) => {
  event.preventDefault()
}

const acceptedTypes = ['.csv', '.xlsx', '.xls']
</script>

<template>
  <DataAssetModuleContainer
    title="Asset details"
    icon-class="i-solar-inbox-archive-line-duotone"
  >
    <div class="flex flex-col gap-4 space-y-8">
      <div>
        <h4 class="mb-4 font-medium">Select file format</h4>
        <CardRadioGroup
          v-model:value="model.fileFormat"
          :options="[
            {
              value: 'sample_file',
              label: 'Sample file upload',
              icon: 'i-custom:data-file-duotone',
              description: 'Select if bucket connection is not set up.',
            },
            {
              value: 'gcs_file_path',
              label: 'GCS file path format',
              icon: 'i-custom:gcp-cloud-duotone',
              description:
                'Select if data asset is available in the client bucket.',
            },
          ]"
        />
      </div>

      <div v-if="model.fileFormat === 'sample_file'">
        <UploadFile
          ref="fileInput"
          :sample-data="model.sampleData"
          :format-file-size="formatFileSize"
          :accepted-types="acceptedTypes"
          label="Upload sample data"
          @upload="handleFileUpload"
          @remove="handleFileRemove"
          @drop="handleDrop"
          @dragover="handleDragOver"
        />
      </div>

      <div v-if="model.fileFormat === 'gcs_file_path'">
        <div class="flex items-center gap-2">
          <LabelWithTooltip
            label="File path format"
            tooltip="Specify the path where the data asset will be located in the client bucket."
          />
        </div>

        <div class="flex">
          <div
            class="flex items-center border border-r-0 border-border rounded-l-lg bg-accent px-4 py-2"
          >
            <span class="text-gray-500">brinks/ingestion</span>
          </div>
          <NInput
            v-model:value="model.filePath"
            class="flex-1"
            placeholder="E.g., data_asset_{%Y_%m_%d}"
          />
        </div>
      </div>

      <div class="w-[30rem]">
        <NCard bordered>
          <div class="flex items-center justify-between gap-4">
            <div class="space-y-1">
              <h4 class="font-medium">Stop pipeline if the asset is missing</h4>
              <p class="text-sm text-gray-600">
                Enable if the asset is mandatory and arrives daily.
              </p>
            </div>
            <NSwitch v-model:value="model.stopPipelineIfMissing" />
          </div>
        </NCard>
      </div>
    </div>
  </DataAssetModuleContainer>
</template>

<style scoped>
:deep(.n-radio-group) {
  width: 100%;
  display: block;
}

:deep(.n-input .n-input__input-el) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
