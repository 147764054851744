<script lang="ts" setup>
import DataAssetProperties, {type FormData} from './DataAssetProperties.vue'

const formData = ref<FormData>({
  assetType: [],
  assetName: '',
  description: '',
  fileFormat: 'sample_file',
  stopPipelineIfMissing: false,
  daysDelay: 0,
  daysDifference: 0,
})

const isBaseAsset = ref(true) // Will be implemented on Data Asset ConMan integration

const handleCreate = () => {
  // Implement save logic for creating assets
  // Will be implemented on Data Asset ConMan integration
}
</script>

<template>
  <DataAssetProperties
    v-model="formData"
    :is-base-asset="isBaseAsset"
    @save="handleCreate"
  />
</template>
