<script setup lang="ts">
import type {DataAssetType} from '../../types/asset'

interface Props {
  options: Array<{label: string; value: string}>
  assetType?: DataAssetType
  isLoading?: boolean
}

defineProps<Props>()

const dataAssetSlug = defineModel<string>('dataAssetSlug')
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center gap-3">
      <div class="w-40">
        <NSelect
          v-model:value="dataAssetSlug"
          class="override-ghost"
          :options="options"
          width="200px"
          :loading="isLoading"
        />
      </div>
      <NTag
        v-if="assetType === 'customer_population'"
        :bordered="false"
        :round="true"
      >
        Customer population asset
      </NTag>
      <NTag
        v-else-if="assetType === 'engagement'"
        :bordered="false"
        class="bg-gray-50"
        :round="true"
      >
        Engagement asset
      </NTag>
    </div>
    <div class="flex gap-2 text-sm text-gray-500">
      <div class="i-solar-calendar-minimalistic-outline" />
      <p class="text-base">Last updated: -</p>
    </div>
  </div>
</template>
