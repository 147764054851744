<script setup lang="ts">
import {computed} from 'vue'

type Props = {
  sampleData?: File
  formatFileSize: (bytes: number) => string
  acceptedTypes: string[]
  label?: string
  isUploading?: boolean
  uploadProgress?: number
}

const emits = defineEmits(['upload', 'remove', 'drop', 'dragover'])

const props = withDefaults(defineProps<Props>(), {
  isUploading: false,
  uploadProgress: 0,
})

const fileInput = ref<HTMLInputElement>()

// Compute the accept string from the acceptedTypes array
const acceptString = computed(() => props.acceptedTypes.join(','))

const handleFileUpload = (event: Event) => {
  emits('upload', event)
}

const handleFileRemove = () => {
  emits('remove')
}

const handleDrop = (event: DragEvent) => {
  emits('drop', event)
}

const handleDragOver = (event: DragEvent) => {
  emits('dragover', event)
}
</script>

<template>
  <div class="h-28 space-y-2">
    <h4 v-if="label" class="font-medium">{{ label }}</h4>
    <input
      ref="fileInput"
      type="file"
      :accept="acceptString"
      class="hidden"
      @change="handleFileUpload"
    />

    <div
      v-if="!props.sampleData && !props.isUploading"
      class="w-full cursor-pointer border-2 border-muted rounded-lg border-dashed px-6 py-4"
      @click="fileInput?.click()"
      @drop="handleDrop"
      @dragover="handleDragOver"
    >
      <div class="flex flex-col items-center justify-center gap-1">
        <div class="i-solar-upload-linear text-lg text-primary" />
        <div class="text-center">
          <p class="font-medium text-primary">Upload a file</p>
          <p class="text-sm text-gray-500">or drop your file here</p>
        </div>
      </div>
    </div>

    <div
      v-else-if="props.isUploading"
      class="h-full flex items-center justify-between border border-border rounded-lg px-6 py-4"
    >
      <div class="w-full flex items-center gap-4">
        <div class="flex items-center justify-center rounded-lg">
          <div
            class="i-solar-cloud-upload-line-duotone h-10 w-10 animate-pulse"
          />
        </div>
        <div class="flex-1">
          <p class="mb-2 font-medium">Uploading...</p>
          <NProgress
            type="line"
            :percentage="props.uploadProgress"
            :height="8"
            processing
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="h-full flex items-center justify-between border border-border rounded-lg px-6 py-4"
    >
      <div class="flex items-center gap-4">
        <div class="flex items-center justify-center rounded-lg">
          <div class="i-custom:file-check-duotone h-10 w-10" />
        </div>
        <div>
          <p class="font-medium">{{ props.sampleData!.name }}</p>
          <p class="text-sm text-gray-600">
            {{ props.formatFileSize(props.sampleData!.size) }}
          </p>
        </div>
      </div>
      <NButton quaternary circle type="error" @click="handleFileRemove">
        <template #icon>
          <div class="i-solar-trash-bin-trash-linear text-lg" />
        </template>
      </NButton>
    </div>
  </div>
</template>

<style scoped>
/* Add any scoped styles if necessary */
</style>
