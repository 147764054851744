import type { AxiosResponse } from 'axios'
import qs from 'qs'
import { DeploymentState, PYTHON_MS_API_PREFIX, UseCaseStatus } from '@shared/data/constants'
import { store } from '@app/store'
import type { StrapiDataArray } from '@shared/utils/Types'
import { getModelFromResponse, getQueryString } from '@shared/utils/helpers'
import http from '@app/utils/http-client'
import type {
  ActionBankChangesModel,
  DagFailedTestModel,
  DagPollModel,
  DeploymentEligibilityModel,
  DeploymentPayloadModel,
  DeploymentTriggerResponse,
  DeploymentsModel,
  DeploymentsTableModel,
  PromotableChangesResponse,
} from '@/deployments/models/server/DeploymentModel'

function listQuery(clientId: number, usecaseId: number, state: string = DeploymentState.PROMOTED) {
  return qs.stringify(
    {
      filters: {
        client: clientId,
        usecases: usecaseId,
        state,
      },
      fields: ['user_comment', 'state', 'updatedAt'],
      populate: {
        pipelines: {
          fields: ['id', 'name'],
        },
        usecases: {
          fields: ['id', 'name'],
        },
      },
    },
    { encodeValuesOnly: true },
  )
}

function populateQuery(clientId: number) {
  return qs.stringify(
    {
      filters: {
        client: clientId,
      },
      populate: {
        pipelines: {
          fields: ['id', 'name'],
        },
        usecases: {
          fields: ['id', 'name'],
        },
        reviewer: {
          fields: ['username', 'email', 'picture'],
        },
        promoter_user: {
          fields: ['username', 'email', 'picture'],
        },
      },
    },
    { encodeValuesOnly: true },
  )
}

export default class DeploymentsService {
  static async migratePipeline(pipeline: string, source_product_env?: string, destination_product_env?: string) {
    if (store.getters.isReadonlyMode) {
      return
    }

    const postData = {
      client: store.getters['client/client'].name,
      pipeline,
      source_product_env,
      destination_product_env,
    }

    return http.post(`${PYTHON_MS_API_PREFIX}/strapi/migrate_pipeline`, postData)
  }

  static async migrateUsecase(pipeline: string, usecase: string, source_product_env?: string, destination_product_env?: string) {
    if (store.getters.isReadonlyMode) {
      return
    }

    const postData = {
      client: store.getters['client/client'].name,
      pipeline,
      usecase,
      source_product_env,
      destination_product_env,
    }

    return http.post(`${PYTHON_MS_API_PREFIX}/strapi/migrate_usecase`, postData)
  }

  static async triggerDeployment(
    pipeline_groups: { [pipeline: string]: Array<string> },
    source_product_env: string,
    destination_product_env: string,
  ): Promise<DeploymentTriggerResponse | void> {
    if (store.getters.isReadonlyMode) {
      return
    }

    const postData = {
      client: store.getters['client/client'].name,
      pipeline_groups,
      source_product_env,
      destination_product_env,
    }
    const response = await http.post<DeploymentTriggerResponse>(`${PYTHON_MS_API_PREFIX}/promotion/trigger_promotion?`, postData)

    return response.data
  }

  static async getUseCaseDeploymentRecord(usecaseId: number) {
    const queryParams = {
      clientId: store.getters['client/client'].id,
      usecaseId,
    }

    const response = await http.get<StrapiDataArray<DeploymentsModel>>(
        `/promotions?${listQuery(queryParams.clientId, queryParams.usecaseId, DeploymentState.PROMOTED)}`,
        {
          cache: false,
        },
    )

    return getModelFromResponse<DeploymentsModel>(response) as DeploymentsModel[]
  }

  static async getDeploymentEligibility(): Promise<DeploymentEligibilityModel> {
    const queryParams = {
      client: store.getters['client/client'].name,
      source_product_env: UseCaseStatus.DRAFT,
      destination_product_env: UseCaseStatus.LIVE,
    }

    const response = await http.get<DeploymentEligibilityModel>(`${PYTHON_MS_API_PREFIX}/promotion/promotion_eligibilities?${getQueryString(queryParams)}`, {
      cache: false,
    })

    return response.data
  }

  static async getDeploymentsByClient(client: number): Promise<DeploymentsModel[]> {
    const response: AxiosResponse = await http.get(`/promotions?${populateQuery(client)}`)
    return getModelFromResponse<DeploymentsModel>(response) as DeploymentsModel[]
  }

  static async postDeployment(deployment: DeploymentPayloadModel): Promise<DeploymentsModel> {
    const response: AxiosResponse = await http.post('/promotions?', { data: deployment })
    return getModelFromResponse<DeploymentsModel>(response) as DeploymentsModel
  }

  static async updateDeployment(payload: DeploymentsModel): Promise<any> {
    const response: AxiosResponse = await http.put(`/promotions/${payload.id}`, { data: payload })
    return getModelFromResponse<DeploymentsModel>(response) as any
  }

  static async cancelDeployment(deployment: DeploymentsTableModel): Promise<boolean> {
    let response: AxiosResponse<{ success: boolean }>
    try {
      const patchData = {
        dag_id: deployment.dag_id,
        state: 'failed',
      }
      response = await http.patch(`${PYTHON_MS_API_PREFIX}/airflow/dag/${deployment.dag_id}/update_state`, patchData)
    }
    catch (e: any) {
      console.error(e)
      throw new Error(e.message)
    }
    return response.data.success
  }

  static pollingTimer: number = Number.NaN

  static async pollDeploymentStatus(dag_id: string): Promise<DagPollModel> {
    if (DeploymentsService.pollingTimer) { clearTimeout(DeploymentsService.pollingTimer) }

    const response = await http.get(`${PYTHON_MS_API_PREFIX}/airflow/dag/${dag_id}/summary`, {
      cache: false,
    })

    return response.data as DagPollModel
  }

  static async getTaskFailLog(dag_id: string, task_id: string): Promise<DagFailedTestModel> {
    const response = await http.get(`${PYTHON_MS_API_PREFIX}/airflow/dag/${dag_id}/logs/${task_id}`, {
      cache: false,
    })

    return response.data as DagFailedTestModel
  }

  static async getActionBankChanges(
    pipeline: string,
    usecase: string,
    source_product_env?: string,
    destination_product_env?: string,
  ):
    Promise<ActionBankChangesModel['response']> {
    const postData = {
      client: store.getters['client/client'].name,
      pipeline,
      usecase,
      source_product_env,
      destination_product_env,
    }

    const response = await http.post<ActionBankChangesModel>(`${PYTHON_MS_API_PREFIX}/promotion/action_bank_changes`, postData)

    return response.data.response
  }

  static async getPromotableChanges(
    client: string,
    pipeline: string,
    experimenter: string,
    depth: number = 0,
    returnValues: boolean = false,
  ): Promise<PromotableChangesResponse> {
    const response = await http.get<PromotableChangesResponse>(
      `/clients/${client}/pipelines/${pipeline}/experimenters/${experimenter}/get-promotable-changes?depth=${depth}&returnValues=${returnValues}`,
      { cache: false },
    )
    return response.data
  }
}
