<script setup lang="ts">
import type { DatePickerProps, SelectInst, SelectProps } from 'naive-ui'
import type { RenderLabel } from 'naive-ui/lib/_internal/select-menu/src/interface'
import type { FormValidationStatus } from 'naive-ui/lib/form/src/interface'
import { ChevronDownIcon, LockClosedIcon } from '@heroicons/vue/24/solid'
import type { SecondaryEffectsRecord } from '@shared/data/secondaryEffectsConstants'
import { useStore } from '@app/store'
import type { ComponentSize } from '@shared/components/types'

import TooltipSpan from '@shared/components/basic/TooltipSpan.vue'

import type { TooltipContent } from '@assets/help/tooltips'
import { DataType } from '@shared/data/constants'
import type { NullString, SelectItem } from '@shared/utils/Types'
import { trackEvent } from '@shared/utils/analytics'
import type { NaiveSelectGroupItem, NaiveSelectItem } from '@shared/utils/naiveProxy'
import { getNaiveSelectGroupOption } from '@shared/utils/naiveProxy'

type DatePickerThemeOverrides = NonNullable<DatePickerProps['themeOverrides']>
type SelectThemeOverrides = NonNullable<SelectProps['themeOverrides']>

interface Props {
  id: string
  tooltipContent?: TooltipContent | string
  options?: (NaiveSelectItem | SelectItem | NaiveSelectGroupItem)[]
  placeholder?: string
  modelValue?: string | number | boolean | null
  label?: NullString
  noDataDescription?: NullString
  dateType?: DataType.DATE | DataType.DATETIME
  renderLabel?: RenderLabel
  error?: NullString
  required?: boolean
  loading?: boolean
  filterable?: boolean
  disabled?: boolean
  locked?: boolean
  lockPlaceholder?: string
  size?: ComponentSize
  multiple?: boolean
  tag?: boolean
  show?: boolean
  maxTagCount?: number
  errorIsAbsolutPositioned?: boolean
  mayHavePrivateData?: boolean
  ignoreGlobalReadOnly?: boolean
  secondaryEffectsPaths?: SecondaryEffectsRecord
}

const props = withDefaults(defineProps<Props>(), {
  show: undefined,
  tag: undefined,
  size: 'medium',
  ignoreGlobalReadOnly: false,
  noDataDescription: undefined,
})

const emit = defineEmits(['update:modelValue', 'blur'])

const store = useStore()

const datePickerThemeOverrides: DatePickerThemeOverrides = {
  common: {
    primaryColor: '#22BD98',
  } as any,
  peers: {
    Input: {
      borderRadius: '7px',
      borderFocus: '2px solid #22BD98',
      caretColor: '#333',
      borderHover: '2px solid #22BD98',
      clearColorHover: '2px solid #22BD98',
      loadingColor: '#22BD98',
      heightMedium: '38px',
      boxShadowFocus: 'none',
    },
    Button: {
      colorPrimary: '#22BD98',
      colorHoverPrimary: '#2dd4bf',
      borderPrimary: 'none',
      borderHoverPrimary: 'none',
      colorSecondary: '#22BD98',
      colorSecondaryHover: '#22BD98',
    },

  },
}

const selectThemeOverrides: SelectThemeOverrides = {
  peers: {
    InternalSelection: {
      textColorDisabled: '#A8A7A7',
    },
  },
}

const selectInstRef = ref<SelectInst>()
const datePickerOpened = ref<boolean>(false)
const isSelectOptionsOpened = ref<boolean>(false)

const localValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)

    if (props.id) {
      trackEvent(props.id, {
        type: 'select',
        action: 'change',
        value: props.mayHavePrivateData ? '*'.repeat(String(value).length || 0) : value,
      })
    }
  },
})

const naiveSelectItems = computed<(NaiveSelectItem | NaiveSelectGroupItem)[] | undefined>(() => {
  return props.options?.map((value: NaiveSelectItem | SelectItem | NaiveSelectGroupItem) => getNaiveSelectGroupOption(value))
})

const showValue = computed(() => {
  return !naiveSelectItems.value?.length && !localValue.value
})

const status = computed<FormValidationStatus | undefined>(() => {
  return props.error ? 'error' : undefined
})

function updated(date: string) {
  localValue.value = date

  datePickerOpened.value = false

  if (props.id) {
    trackEvent(props.id, {
      type: 'date-picker',
      action: 'select',
      value: date?.toString(),
    })
  }
}

const focus: () => void = () => {
  selectInstRef.value?.focus?.()
}

function selectDateClickHandler() {
  datePickerOpened.value = true
  isSelectOptionsOpened.value = false
}

function selectOptionClickHandler(show: boolean) {
  if (show) {
    datePickerOpened.value = false
  }
  isSelectOptionsOpened.value = show
}

const showOptions = computed(() => {
  if (props.show !== undefined) {
    return props.show
  }

  if (!isSelectOptionsOpened.value) {
    return false
  }

  return undefined
})

const globalReadOnlyMode = computed(() => store.getters.isReadonlyMode)

defineExpose({
  focus,
})
</script>

<template>
  <div :class="[!($attrs.class as string)?.includes('w-') ? 'w-full' : '']" class="relative">
    <span class="flex">
      <label v-if="label" :for="id" class="mb-1 block font-medium"> {{ label }}<sup v-show="required">*</sup></label>
      <TooltipSpan
        v-if="tooltipContent"
        class="ml-1"
        :tooltipContent="typeof tooltipContent === 'string' ? { text: tooltipContent } as TooltipContent : tooltipContent"
        hasIcon
      />
      <SecondaryEffectsTooltip
        v-if="secondaryEffectsPaths"
        :pathRecords="secondaryEffectsPaths"
        :show="isSelectOptionsOpened"
      />
    </span>

    <div
      v-if="locked"
      :title="lockPlaceholder"
      class="w-full flex cursor-not-allowed items-center justify-between border border-gray-200 rounded-md bg-gray-50 text-gray-500"
      :style="{ height: { small: '24px', large: '80px', medium: '36px', tiny: '24px' }[size] }"
    >
      <div class="flex items-center justify-center px-2">
        <LockClosedIcon class="mr-2 h-4 w-4 cursor-not-allowed text-gray-500" />
        {{ lockPlaceholder }}
      </div>

      <ChevronDownIcon class="mr-2 mt-1 h-5 w-5 text-offerfit-gray" />
    </div>

    <NDatePicker
      v-else-if="dateType"
      v-show="datePickerOpened"
      :id="id"
      class="absolute z-50 shadow -right-56 -top-40"
      :type="dateType"
      :format="dateType === DataType.DATE ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'"
      :themeOverrides="datePickerThemeOverrides"
      clearable panel
      :disabled="disabled || (!ignoreGlobalReadOnly && globalReadOnlyMode)"
      :closeOnSelect="true"
      :on-update:formatted-value="updated"
    />
    <NSelect
      v-else
      :id="id"
      ref="selectInstRef"
      v-model:value="localValue"
      :disabled="disabled || (!ignoreGlobalReadOnly && globalReadOnlyMode)"
      :required="required"
      class="w-full rounded-lg shadow-sm"
      :filterable="filterable"
      :placeholder="placeholder"
      :options="naiveSelectItems"
      :renderLabel="renderLabel"
      :status="status"
      :size="size"
      :multiple="multiple"
      :show="showOptions"
      :tag="tag"
      :title="localValue"
      :maxTagCount="maxTagCount"
      :loading="loading"
      :virtualScroll="true"
      :themeOverrides="selectThemeOverrides"
      :consistent-menu-width="false"
      @update:value="!multiple && $emit('blur')"
      @update:show="selectOptionClickHandler"
      @blur="$emit('blur')"
    >
      <template #empty>
        <NEmpty v-if="!naiveSelectItems?.length && !showValue" :description="noDataDescription" />
      </template>
      <template v-if="dateType" #action>
        <div class="cursor-pointer" @click="selectDateClickHandler">
          Select {{ dateType }}
        </div>
      </template>
      <template v-if="showValue" #value>
        {{ localValue }}
      </template>
    </NSelect>
    <div v-if="error" class="pointer-events-none items-center" :class="{ absolute: errorIsAbsolutPositioned }">
      <span class="ml-1 text-sm text-red-500">{{ error }}</span>
    </div>
  </div>
</template>
