<script setup lang="ts">
export type CardRadioGroupOption<T = string> = {
  label: string
  value: T
  icon?: string
  description?: string
}

type Props<T = string> = {
  options: Array<CardRadioGroupOption<T>>
  showRadio?: boolean
  size?: 'small' | 'medium' | 'large'
}

const modelValue = defineModel<string>()

withDefaults(defineProps<Props>(), { options: () => ([]),showRadio: true,size: 'large', })

const thingClasses = computed(() => ({
  'px-2 py-1.5': __props.size === 'small',
  'px-2.5 py-2': __props.size === 'medium',
  'px-4 py-3': __props.size === 'large',
}))

const iconClasses = computed(() => ({
  'text-sm': __props.size === 'small',
  'text-md': __props.size === 'medium',
  'text-lg': __props.size === 'large',
}))

const labelClasses = computed(() => ({
  'text-sm': __props.size === 'small',
  'text-base': __props.size === 'medium' || __props.size === 'large',
}))

const descriptionClasses = computed(() => ({
  'text-xs': __props.size === 'small',
  'text-sm': __props.size === 'medium' || __props.size === 'large',
}))
</script>

<template>
  <NRadioGroup
    class="@container"
    :value="modelValue"
    @update:value="modelValue = $event"
  >
    <div
      class="grid grid-auto-rows-[1fr] grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4"
    >
      <div v-for="option in options" :key="option.value" class="h-full">
        <NThing
          content-indented
          :class="[
            {
              'border-primary bg-accent': modelValue === option.value,
              'border-border': modelValue !== option.value,
            },
            thingClasses,
          ]"
          class="h-full cursor-pointer border rounded-md bg-card transition-colors hover:bg-accent"
          @click="modelValue = option.value"
        >
          <template #avatar>
            <div class="h-full flex items-center gap-2">
              <NRadio v-if="showRadio" :value="option.value" :size="size" />
              <div
                v-if="option.icon"
                :class="[option.icon, iconClasses]"
                class="text-primary-foreground"
              />
            </div>
          </template>
          <template #header>
            <span :class="labelClasses" class="font-medium text-foreground">
              {{ option.label }}
            </span>
          </template>
          <template #description>
            <p
              v-if="option.description"
              :class="descriptionClasses"
              class="leading-tight text-muted-foreground"
            >
              {{ option.description }}
            </p>
          </template>
        </NThing>
      </div>
    </div>
  </NRadioGroup>
</template>

<style scoped>
:deep(.n-thing .n-thing-main .n-thing-header) {
  @apply mb-0;
}
:deep(.n-thing-avatar) {
  @apply mt-0;
}
</style>
