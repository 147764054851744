import {getAllAssetsForPipelineApiClientsClientSlugPipelinesPipelineSlugAssetsGet as getAllDataAssets} from '@offerfit/conman-sdk'
import type {DataAssetModel} from '@level3/features/dataAssets/types/asset.ts'

// TODO: Add pagination when ConMan is updated
export const getDataAssets: (
  clientSlug: string,
  pipelineSlug: string,
) => Promise<DataAssetModel[]> = async (
  clientSlug: string,
  pipelineSlug: string,
) => {
  try {
    const {data} = await getAllDataAssets(clientSlug, pipelineSlug)

    // TODO: Remove those validations once ConMan adjust types
    if (!Array.isArray(data.data)) {
      if (
        'errors' in data.data &&
        data.data.errors &&
        data.data.errors.length > 0
      ) {
        throw new Error(data.data.errors.join(', '))
      }

      throw new TypeError('Unexpected API response')
    }

    return data.data.map((asset) => ({
      slug: asset.slug,
      name: asset.name,
      description: asset.description ?? '',
      type: 'customer_population', // TODO: Update this once ConMan is updated
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}
