<script setup lang="ts">
import AppSettings from '@app/AppSettings.ts'
import YellowWarning from '@shared/components/basic/static/YellowWarning.vue'
import router from '@router/index'
import { useLvl4Usecase } from '@/level4/composables/useLvl4Usecase.ts'
import { CreateVariantsSteps, Level4Module } from '@/level4/data/forms'
import type { FormExposedProperties } from '@/level4/utils/level4Forms'
import { CAMPAIGN_PLATFORM_TERMINOLOGY, GENERATED_VARIANTS_LIMIT, PLATFORM_DISPLAY_NAME } from '@/level4/data/l4_constants'
import { useEmailVariantPreview } from '@/level4/composables/useEmailVariantPreview'
import { useExperimenterFormState } from '@/level4/composables/useExperimenterFormState'

const { clientName, pipelineName, experimenterName } = AppSettings

const {
  isLive,
  experimenterFormState,
  updateExperimenterFormState,
  reuseImportedTemplatesForOfferfit,
  createOrUpdatePlatformTemplates,
  updateEmailActionBank,
  updateTemplateDescription,
  mappedEmailTemplatesForExperimenter,
  mappedGeneratedVariantsCombinatoryListTotal,
  everyTemplatesHasMinOneVariant,
} = useExperimenterFormState()

const { showEmailVariantPreviewModal, templateHtml, replaceMap, previewVariantClickHandler } = useEmailVariantPreview()
const { isSkipTemplateUpsertFlagTrue } = useLvl4Usecase(clientName.value, pipelineName.value, experimenterName.value)

const isSubmitClicked = ref<boolean>(false)
const isWarningOpen = ref<boolean>(true)

function handleEditVariantsClick(campaignTemplateId: string) {
  let module
  let step
  if (!isLive.value) {
    module = Level4Module.CHOOSE_ELEMENTS
  }
  else if (experimenterFormState.value?.variantsDataMap[campaignTemplateId]?.toneGuidanceFeedback === undefined
    && (experimenterFormState.value?.variantsDataMap[campaignTemplateId]?.varySubjectLine
    || experimenterFormState.value?.variantsDataMap[campaignTemplateId]?.varyCTA)
  ) {
    step = CreateVariantsSteps.TONE_GUIDANCE
  }
  else if (experimenterFormState.value?.variantsDataMap[campaignTemplateId]?.varySubjectLine) {
    step = CreateVariantsSteps.SELECT_SUBJECT_LINES
  }
  else if (experimenterFormState.value?.variantsDataMap[campaignTemplateId]?.varyCTA) {
    step = CreateVariantsSteps.CTA_TEXT_SELECTION
  }
  else if (experimenterFormState.value?.variantsDataMap[campaignTemplateId]?.varyHeroImage) {
    step = CreateVariantsSteps.HERO_IMAGE_SELECTION
  }

  router.push({
    name: module || Level4Module.CREATE_VARIANTS,
    params: {
      templateId: campaignTemplateId,
    },
    query: {
      step,
    },
  })
}

const variantLimitReached = computed(() => {
  return mappedGeneratedVariantsCombinatoryListTotal.value! >= GENERATED_VARIANTS_LIMIT
})

function importTemplatesClickHandler() {
  router.push({ name: Level4Module.TEMPLATES_SELECTION })
}

function deleteVariantClickHandler(templateId: string, variantId: string) {
  const variantsDataMap = toRaw(experimenterFormState.value?.variantsDataMap)
  variantsDataMap![templateId].generatedVariants = variantsDataMap![templateId].generatedVariants!.filter((variant) => {
    return variant.id !== variantId
  })
  updateExperimenterFormState({ variantsDataMap })
}

function deleteEmailClickHandler(campaignTemplateId: string) {
  // TODO DELETE TEMPLATES FROM KLAVIYO AS WELL

  if (experimenterFormState.value?.variantsDataMap[campaignTemplateId]) {
    const variantsDataMap = toRaw(experimenterFormState.value?.variantsDataMap)
    delete variantsDataMap[campaignTemplateId]
    updateExperimenterFormState({ variantsDataMap })
  }
  updateExperimenterFormState({ currentImportedTemplates: experimenterFormState.value?.currentImportedTemplates.filter(value => value.id !== campaignTemplateId) })
  const offerfitCreatedTemplates = toRaw(experimenterFormState.value?.offerfitCreatedTemplates)

  if (campaignTemplateId in experimenterFormState.value!.offerfitCreatedTemplates) {
    offerfitCreatedTemplates![campaignTemplateId].deleted = true
    updateExperimenterFormState({ offerfitCreatedTemplates })
  }
  // const updatedTemplates = offerfitCreatedTemplates.filter(key, value => value.id !== campaignTemplateId) }
  // updateExperimenterFormState({ offerfitCreatedTemplates: updatedTemplates)
}

const subtitle = `Create at least one variant for each base email. You can also import additional base emails from ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']}`
const warningTitle = `Adding or deleting base emails will require ${PLATFORM_DISPLAY_NAME[experimenterFormState.value?.platform || '']} updates before relaunching`
const warningSubTitle = `If you change the base emails your Experimenter uses, you will need to update the ${CAMPAIGN_PLATFORM_TERMINOLOGY[experimenterFormState.value?.platform || '']} associated with your Experimenter. You may want to pause the Experimenter to make these changes. Changes are not required if you are just editing variants of existing base emails.`
const isLoadingMessage = computed(() => experimenterFormState.value?.platform === Level4Module.SFMC && isSubmitClicked.value)
defineExpose<FormExposedProperties>({
  // TODO: limit applies only to trial experimenters
  dataIsComplete: computed(() => mappedGeneratedVariantsCombinatoryListTotal.value! <= GENERATED_VARIANTS_LIMIT && mappedGeneratedVariantsCombinatoryListTotal.value! > 0 && everyTemplatesHasMinOneVariant.value),
  submitHandler: async () => {
    isSubmitClicked.value = true
    if (isSkipTemplateUpsertFlagTrue.value) {
      await reuseImportedTemplatesForOfferfit()
    }
    else {
      await createOrUpdatePlatformTemplates()
    }
    await updateEmailActionBank()
    isSubmitClicked.value = false
  },
})
</script>

<template>
  <WizardFormPageLayout containerSize="full" title="These are the base emails you’ve chosen." :subtitle="subtitle">
    <template #header-extra>
      <div w="full" flex="~" items="center" justify="end">
        <NButton type="primary" quaternary strong @click="importTemplatesClickHandler">
          Import base emails
          <template #icon>
            <div class="i-solar-download-outline" />
          </template>
        </NButton>
      </div>
    </template>
    <div class="email-templates-variants-list-table" w="full" flex="~ col" gap="4">
      <YellowWarning
        v-if="experimenterFormState?.sfmcJourneySubmitClicked && isWarningOpen" closable :title="warningTitle"
        :subtitle="warningSubTitle" @dismiss="isWarningOpen = false"
      />
      <GeneratedVariantsLimitReached v-if="variantLimitReached" />
      <EmailTemplatesListTable
        :isEditable="true"
        :emailRows="mappedEmailTemplatesForExperimenter"
        :elementsToVaryList="['Subject Lines', 'CTA']" w="full" @click:edit-variants="handleEditVariantsClick"
        @click:create-variants="handleEditVariantsClick" @click:preview-variant="previewVariantClickHandler"
        @click:delete-variant="deleteVariantClickHandler" @click:delete-email="deleteEmailClickHandler"
        @update:template-description="updateTemplateDescription"
      />
      <GeneratedVariantsLimitReached v-if="variantLimitReached" />
    </div>
  </WizardFormPageLayout>
  <EmailVariantPreviewModal
    v-model:show="showEmailVariantPreviewModal" :html="templateHtml"
    :replaceMap="replaceMap"
  />
  <div v-if="isLoadingMessage" class="relative top-15 flex justify-center text-sm text-offerfit-bright-purple font-medium">
    This may take a few minutes...
  </div>
</template>
