import { SentryError } from '@sentry/utils'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import { store } from '@app/store'
import type { ROLE_TYPE } from '@shared/data/constants'
import Global from '@shared/utils/global'
import { usePermissions } from '@/auth/composables/usePermissions'

type ErrorMessage = string
export function handlePortalError<T extends Error | ErrorMessage>(error: T, props: {
  defaultUserErrorText: string
  showErrorToast?: boolean
  trackErrorInSentry?: boolean
  detailedErrorText?: string
  showInBrowserConsole?: boolean
  roleErrorTextMap?: Partial<Record<ROLE_TYPE, ErrorMessage>>
} = {
  defaultUserErrorText: '',
  showErrorToast: true,
  trackErrorInSentry: true,
}) {
  if (axios.isCancel(error)) {
    // eslint-disable-next-line no-console
    console.info('Request canceled')
    return
  }

  const { isLevel4Customer } = usePermissions()

  const defaultProps = {
    defaultUserErrorText: '',
    detailedErrorText: '',
    showErrorToast: true,
    trackErrorInSentry: true,
    showInBrowserConsole: !isLevel4Customer.value,
  }

  const logProps = { ...defaultProps, ...props }

  if (logProps.trackErrorInSentry) {
    if (error instanceof Error) {
      const newError = new Error(
        logProps.detailedErrorText ?? logProps.defaultUserErrorText,
        { cause: error },
      )
      Sentry.captureException(newError)
    }
    else {
      Sentry.captureException(new SentryError(error))
    }
  }

  if (logProps.showErrorToast) {
    const userErrorText = props.roleErrorTextMap?.[store.getters['user/role']?.type as ROLE_TYPE] ?? props.defaultUserErrorText
    setTimeout(() => {
      Global.error(userErrorText)
    }, 10)
  }

  if (logProps.showInBrowserConsole) { console.error(logProps.defaultUserErrorText, logProps.detailedErrorText ?? '', error) }
}
