export const usePath = () => {
  const clientSlug = useRouteParams<string>('clientSlug')
  const pipelineSlug = useRouteParams<string>('pipelineSlug')
  const usecaseSlug = useRouteParams<string>('usecaseSlug')
  const dataAssetSlug = useRouteParams<string>('dataAssetSlug')

  return {
    clientSlug,
    pipelineSlug,
    usecaseSlug,
    dataAssetSlug,
  }
}
