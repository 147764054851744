<script setup lang="ts">
import type {DataAssetStatus} from '@level3/features/dataAssets/types/asset'

withDefaults(defineProps<{
  status: DataAssetStatus
}>(), {  })

const statusColors = computed(() => {
  if (__props.status === 'concluded')
    return {
      color: '#dcfce7',
      textColor: '#15803d',
      borderColor: '#dcfce7',
    }
  if (__props.status === 'unrun_changes' || __props.status === 'running')
    return {
      color: '#ffedd5',
      textColor: '#f97316',
      borderColor: '#ffedd5',
    }
  return {
    color: '#f9fafb',
    textColor: '#6b7280',
    borderColor: '#f9fafb',
  }
})

const statusIcon = computed(() => {
  if (__props.status === 'concluded') return 'i-solar-check-circle-bold text-green-700'
  if (__props.status === 'unrun_changes')
    return 'i-solar-danger-circle-bold text-orange-500'
  if (__props.status === 'draft') return 'i-solar-file-bold text-gray-500'
  return 'i-solar-refresh-circle-bold text-orange-500'
})

const statusText = computed(() => {
  if (__props.status === 'concluded') return 'Concluded'
  if (__props.status === 'unrun_changes') return 'Unrun changes'
  if (__props.status === 'draft') return 'Draft'
  return 'Running in progress...'
})
</script>

<template>
  <NTag
    round
    size="small"
    class="w-fit flex items-center"
    :color="statusColors"
  >
    <template #icon>
      <span class="h-4 w-4" :class="statusIcon"></span>
    </template>
    <p class="text-sm">{{ statusText }}</p>
  </NTag>
</template>
