<script setup lang="ts">
import {useFieldTypes, type FieldTypeValue} from './useFieldTypes.ts'

type Props = {
  type: FieldTypeValue
  size?: 'xs' | 'sm' | 'base'
  boxed?: boolean
  showLabel?: boolean
  labelPosition?: 'left' | 'right'
}

withDefaults(defineProps<Props>(), { size: 'xs',boxed: true,showLabel: false,labelPosition: 'right', })

const {getFieldTypeByType} = useFieldTypes()

const iconData = computed(() => {
  return getFieldTypeByType(__props.type)
})

const icon = computed(() => iconData.value?.icon)
const color = computed(() => iconData.value?.color)
const boxColor = computed(() => iconData.value?.boxColor)

const iconSizeMap = {
  xs: 'h-4 w-4',
  sm: 'h-6 w-6',
  base: 'h-8 w-8',
} as const

const labelSizeMap = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
} as const

const iconSizeClass = computed(() => iconSizeMap[__props.size])
const labelSizeClass = computed(() => labelSizeMap[__props.size])
</script>

<template>
  <div class="flex items-center">
    <template v-if="showLabel && labelPosition === 'left'">
      <span class="mr-1 font-medium" :class="labelSizeClass">{{
        iconData.value?.label || type
      }}</span>
    </template>
    <div
      class="field-type-icon-wrapper rounded-sm p-[2px]"
      :class="[boxed ? boxColor : 'bg-transparent']"
    >
      <div :class="[icon, color, iconSizeClass]" class="field-type-icon" />
    </div>
    <template v-if="showLabel && labelPosition === 'right'">
      <span class="ml-1" :class="['text-foreground', labelSizeClass]">{{
        iconData.value?.label || type
      }}</span>
    </template>
  </div>
</template>
