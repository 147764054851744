import type { ActionContext, ActionTree } from 'vuex'

import type { AppState } from '@app/store/app'
import type { ColumnMappingPayload, DataAssetWrapper, PipelineUpdateContext, StoreDataChangePayload } from '@shared/utils/Types'
import Global from '@shared/utils/global'
import { isEqual } from '@shared/utils/helpers'
import { pipelineLocallyUpdatedAt } from '@/pipelines/composables/usePipelineConfig'
import type { DeleteFeaturePayload, NewFeaturePayload, defaultCreateFeatureWrapper } from '@/data-assets/models/FeaturesModel'
import type { PipelineModel } from '@/pipelines/models/server/PipelineModel'
import PipelineService from '@/pipelines/services/PipelineService'
import type { PipelineStateType } from '@/pipelines/store/state'

export const actions: ActionTree<PipelineStateType, AppState> = {
  async getPipelinesByClient({ commit }: ActionContext<PipelineStateType, AppState>, { clientID, tags }: { clientID: number, tags?: string[] }) {
    if (!clientID) { throw new Error('getPipelinesByClient: Client "id" is required to make a request') }

    try {
      const pipelinesData: PipelineModel[] = await PipelineService.getPipelines(clientID, tags)
      commit('pipelines', pipelinesData)
    }
    catch (e: any) {
      Global.error(e.message)
    }
  },
  async updatePipeline({ getters, rootGetters }: ActionContext<PipelineStateType, AppState>, context: PipelineUpdateContext) {
    if (!getters.pipeline?.id) {
      Global.error('Pipeline is not loaded')
      return
    }

    if (rootGetters.isReadonlyMode) {
      return
    }

    // const serverUpdateTime = await PipelineService.getServerPipelineLastUpdateAt(getters.pipeline.id)
    // const { pipelineHasLocalRemoteConflict, hasAcknowledgedLocalRemoteConflict, pipelineLocallyUpdatedAt } = usePipelineConfig()
    // const lastUpdated: Date = pipelineLocallyUpdatedAt?.value || getters.pipeline.updatedAtDate
    //
    // // If making rapid changes, it's possible to save a change, then make a second change,
    // //  before the first change had been fully processed and saved
    // //  This erroneously triggered a "changes were made" config message - the local state is still in sync
    // // The below checks to see if the pipelines match because everything is synced (pipelinesMatched)
    // //  or that the local last-updated state is *ahead* of the server, indicating local state is correct still
    // //  (when the local state is ahead of the server, nothing's at risk of being lost on an additional save
    // //    because a save carries the entire state of the config file - inclusive of any other change made before)
    // //  We deal with this by making sure the server changes were within 5 seconds
    // //    (either way) of the local change it's considered close enough
    // const pipelinesMatched =
    //   serverUpdateTime.getTime() <= lastUpdated.getTime() ||
    //   Math.abs(lastUpdated.getTime() - serverUpdateTime.getTime()) <= 5 * 1000
    //
    // if (!pipelinesMatched) {
    //   const breadcrumb = {
    //     category: 'debug',
    //     message: 'Timestamp details',
    //     level: 'debug' as SeverityLevel,
    //     data: {
    //       pipelineId: getters.pipeline.id,
    //       serverUpdateMS: serverUpdateTime.getTime(),
    //       serverUpdate: serverUpdateTime.toLocaleString('en-GB', { timeZone: 'UTC' }),
    //       localPipelineUpdateMS: pipelineLocallyUpdatedAt?.value?.getTime(),
    //       localPipelineUpdate: pipelineLocallyUpdatedAt?.value?.toLocaleString('en-GB', { timeZone: 'UTC' }),
    //       localPipelineStoreUpdateMS: getters.pipeline.updatedAtDate.getTime(),
    //       localPipelineStoreUpdate: getters.pipeline.updatedAtDate.toLocaleString('en-GB', { timeZone: 'UTC' }),
    //       calculatedLastUpdateMS: lastUpdated.getTime(),
    //       diffBetweenServerAndCalcLocal: Math.abs(lastUpdated.getTime() - serverUpdateTime.getTime()),
    //       pipelineHasLocalRemoteConflict: pipelineHasLocalRemoteConflict.value,
    //       hasAcknowledgedLocalRemoteConflict: hasAcknowledgedLocalRemoteConflict.value,
    //       pipelineMatchedCheck: pipelinesMatched
    //     }
    //   } as Breadcrumb
    //   Sentry.addBreadcrumb(breadcrumb)
    //   Sentry.captureMessage('[PIPELINE] Local client config update mismatch with server ')
    //   pipelineHasLocalRemoteConflict.value = true
    //   hasAcknowledgedLocalRemoteConflict.value = false
    //   Global.error('Unable to save. Changes were made to config data outside this window')
    //   return
    // }

    try {
      pipelineLocallyUpdatedAt.value = new Date()
      const updatedPipeline = await PipelineService.updatePipeline(getters.pipeline?.id, getters.pipeline, context)
      // I think, in an ideal world, we'd be able to just update the pipeline itself with the fresh-from-server pipeline
      //  but there currently seems to be some circular dependencies between metadata and/or assets that causes the
      //  app to infinite loop between assets/metadata -> updatePipeline -> commit('pipeline', ...) -> assets/metadata
      //  and I'm not sure where to start unraveling that, plus updatePipeline never previously updated the pipeline
      //    - it'd be new.
      //  So we're just going to... not do that and track lastUpdated time separately from the pipeline itself
      //  2022-09-27
      // commit('pipeline', updatedPipeline)

      if (updatedPipeline && updatedPipeline.updatedAtDate) {
        pipelineLocallyUpdatedAt.value = updatedPipeline.updatedAtDate
      }
    }
    catch (e: any) {
      Global.error(e.message)
    }
  },
  async addPipeline({ commit, rootGetters }: ActionContext<PipelineStateType, AppState>, value: PipelineModel) {
    if (rootGetters.isReadonlyMode) {
      return
    }

    commit('addPipeline', value)
  },
  async deleteDataAssetsByKey({ commit, dispatch, rootGetters }: ActionContext<PipelineStateType, AppState>, key: string) {
    if (rootGetters.isReadonlyMode) {
      return
    }

    commit('removePreprocessAsset', key)
    commit('removeAssetInFeatureConfig', key)
    commit('removeAssetFromMetadata', key)
    await dispatch('updatePipeline')
  },
  async addOrUpdateDataAsset(
    { commit, state, rootGetters }: ActionContext<PipelineStateType, AppState>,
    payload: DataAssetWrapper,
  ) {
    if (rootGetters.isReadonlyMode) {
      return
    }

    const preprocessAssetSection = structuredClone(toRaw(state.pipeline?.config?.preprocess?.assets?.[payload.key]))
    if (!isEqual(preprocessAssetSection, payload.asset)) {
      commit('addOrUpdatePreprocessAsset', payload)
      if (state.pipeline?.metadata.assets[payload.key]) {
        commit(
          'updateObjectInStore',
          {
            fieldObject: state.pipeline?.metadata.assets[payload.key],
            key: 'updatedAt',
            value: new Date().toUTCString(),
          } as StoreDataChangePayload,
          { root: true },
        )
      }
    }
  },
  savePipelineConfig({ commit, dispatch, rootGetters }: ActionContext<PipelineStateType, AppState>, pipelineConfig: any) {
    if (rootGetters.isReadonlyMode) {
      return
    }
    commit('updatePipelineConfig', pipelineConfig)
    dispatch('updatePipeline')
  },
  async saveMetadataConfig({ commit, dispatch, rootGetters }: ActionContext<PipelineStateType, AppState>, metadataConfig: any) {
    if (rootGetters.isReadonlyMode) {
      return
    }
    commit('updatePipelineMetadata', metadataConfig)
    await dispatch('updatePipeline')
  },
  savePreprocessConfig({ commit, dispatch, rootGetters }: ActionContext<PipelineStateType, AppState>, preprocessConfig: any) {
    if (rootGetters.isReadonlyMode) {
      return
    }
    commit('updatePreprocessConfig', preprocessConfig)
    dispatch('updatePipeline')
  },
  saveFeaturesConfig({ commit, dispatch, rootGetters }: ActionContext<PipelineStateType, AppState>, featuresConfig: any) {
    if (rootGetters.isReadonlyMode) {
      return
    }
    commit('updateFeaturesConfig', featuresConfig)
    dispatch('updatePipeline')
  },
  saveNewAssetFeaturesConfig(
    { commit, dispatch, rootGetters }: ActionContext<PipelineStateType, AppState>,
    featureConfig: defaultCreateFeatureWrapper,
  ) {
    if (rootGetters.isReadonlyMode) {
      return
    }
    commit('updateFeatureAssetsConfig', featureConfig)
    dispatch('updatePipeline')
  },
  addNewFeatureToConfig(
    { commit, dispatch, rootGetters }: ActionContext<PipelineStateType, AppState>,
    newFeature: NewFeaturePayload,
  ) {
    if (rootGetters.isReadonlyMode) {
      return
    }
    if (Object.keys(newFeature.wrapper).length) {
      commit('addNewFeatureWrapper', newFeature)
    }
    commit('addAssetFeatureConfig', newFeature)
    dispatch('updatePipeline')
  },
  updateColumnMappingInConfig(
    { commit, rootGetters }: ActionContext<PipelineStateType, AppState>,
    columnMap: ColumnMappingPayload,
  ) {
    if (rootGetters.isReadonlyMode) {
      return
    }
    commit('updateColumnMapping', columnMap)
  },
  deleteAssetFromConfig(
    { commit, dispatch, rootGetters }: ActionContext<PipelineStateType, AppState>,
    feature: DeleteFeaturePayload,
  ) {
    if (rootGetters.isReadonlyMode) {
      return
    }
    commit('deleteFeatureFromConfig', feature)
    dispatch('updatePipeline')
  },
}
