import {usePath} from '@level3/features/navigation/composables/usePath'
import type {DataAssetType} from '@level3/features/dataAssets/types/asset.ts'
import {useDataAssets} from './useDataAssets.js'

type DataAssetOption = {
  label: string
  value: string
  type: DataAssetType
}

export function useDataAssetOptions() {
  const {dataAssets, isLoadingDataAssets} = useDataAssets()
  const {dataAssetSlug} = usePath()

  const options = computed<DataAssetOption[]>(() => {
    if (!dataAssets.value || !Array.isArray(dataAssets.value)) return []
    return dataAssets.value.map((asset) => ({
      label: asset.name,
      value: asset.slug,
      type: asset.type,
    }))
  })

  const assetType = computed<DataAssetType | undefined>(() => {
    if (
      !isLoadingDataAssets.value &&
      Array.isArray(dataAssets.value) &&
      dataAssets.value.length === 0
    ) {
      return 'customer_population'
    }

    return undefined
  })

  return {
    options,
    assetType,
    dataAssetSlug,
    isLoadingDataAssets,
  }
}
